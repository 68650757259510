<template>
	<Layout>
		<PageHeader :title="PageHeaderT" :items="PageHeaderItems" />
		<div class="block_content d-flex flex-column">
			
		</div>
	</Layout>
</template>

<script>
	export default {
		data() {
			return {
				/* 面包屑 */
				PageHeaderT: this.$t('user.title'),
				PageHeaderItems: [
					{
						text: this.$t('user.title'),
						active: true,
						href: '/user'
					}
				],
			}
		}
	}
</script>

<style>
</style>